<template>
  <XSelect
      :value="value"
      :items="items"
      :label="label"
      @input="$emit('input', $event)"
      :item-value="!hash ? 'id' : 'hash'"
      item-text="name"
      :required="!!value && required"/>
</template>

<script>
import XSelect from '@/components/basic/XSelect';

export default {
  name: 'ProjectSelect',
  components: {XSelect},
  props: {
    value: Number,
    hash: Boolean,
    label: String,
    required: Boolean,
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    if (!this.value && this.required) {
      const project = !this.hash ? this.$store.state.project : this.$store.state.projectHash;
      this.$emit('input', project);
    }

    if (!this.hash) {
      this.services.user.getProjectNames((projects) => {
        this.items = projects;
      });
    } else {
      this.services.user.getProjectNamesHash((projects) => {
        this.items = projects;
      });
    }
  },
};
</script>

<style scoped>

</style>