<template>
  <XDialog title="Explorer Import" :value="value" @input="$emit('input', $event)" width="1039" :loading="loading">
    <template #dialog-content>
      <div class="explorer-import-dialog-content">
        <XRadioGroup v-model="importType" :items="importTypes" required/>
        <InputRow>
          <v-form ref="form" v-model="valid" class="flex-grow-1">
            <XFileInput
                v-model="file"
                label="File Name"
                placeholder="Click here to upload a file of up to 2MB"
                accept=".csv"
                :rules="getFileRules()"/>
          </v-form>
          <XBtn icon="mdi-database-import" text="Import" color="primary" @click="importFile"/>
        </InputRow>
        <XDataTable
            :headers="historyTableHeaders"
            :value="items"
            height="361"
            :loading="tableLoading"
            no-page-controls
            no-elevation/>
      </div>
    </template>
  </XDialog>
</template>

<script>
import XDialog from '@/components/basic/XDialog';
import XRadioGroup from '@/components/basic/XRadioGroup';
import XBtn from '@/components/basic/XBtn';
import InputRow from '@/components/basic/InputRow';
import XDataTable from '@/components/basic/XDataTable';
import XFileInput from '@/components/basic/XFileInput';

export default {
  name: 'ExplorerImportDialog',
  components: {
    XFileInput,
    XDataTable,
    InputRow,
    XBtn,
    XRadioGroup,
    XDialog,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      file: null,
      importType: '',
      importTypes: [
        {
          text: 'Tag Modify',
          value: 'TAG_MODIFY',
        },
        {
          text: 'Tags Mappings',
          value: 'TAGS_MAPPINGS',
        },
      ],
      historyTableHeaders: [
        {
          text: 'Date',
          value: 'date',
          formatter: value => this.unixToDateTimeString(value),
          width: 142,
        },
        {
          text: 'Type',
          value: 'type',
          formatter: (value) => {
            const type = this.importTypes.find(x => x.value === value);
            if (type) return type.text;
            return value;
          },
          width: 191,
        },
        {
          text: 'File Name',
          value: 'objectStoreLink',
        },
        {
          text: 'Status',
          value: 'status',
          width: 100,
        },
        {
          text: 'Message',
          value: 'message',
          width: 180,
        },
      ],
      loading: true,
      tableLoading: true,
      items: [],
      valid: true,
    };
  },
  created() {
    const orgaName = this.$store.state.organization.name;
    if (orgaName && orgaName.includes('Vodafone') || orgaName.includes('Acctopus')) {
      this.importTypes.push(
          {
            text: 'VF Shipping List',
            value: 'VF_SHIPPING',
          },
          {
            text: 'VF Influx DB Token List',
            value: 'VF_INFLUX_TOKEN',
          },
          {
            text: 'VF Force Business Status',
            value: 'FORCE_STATUS',
          },
          {
            text: 'VF DeltaQ Keys',
            value: 'VF_DELTAQ',
          });
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.file = null;
          this.loadData();
        }
      },
    },
  },
  methods: {
    importFile() {
      this.$refs.form.validate();
      this.$nextTick(() => {
        if (!this.valid) {
          return;
        }
        this.services.explorerStatus.importCsv(this.file, this.importType, () => {
          this.showNotification(`Upload of ${this.file.name} complete. You can check the progress in the list.`, 5000);
          setTimeout(this.loadData, 1000);
        });
      });
    },
    getFileRules() {
      return [
        this.getRequiredRule(),
        () => !!this.file || this.getRequiredMessage(),
        v => !!v && v.size <= 2000 || 'Maximum file size is 2 MB.',
      ];
    },
    loadData() {
      this.tableLoading = true;
      this.services.explorerStatus.getCsvImportStatus((items) => {
        this.items = items;
        this.tableLoading = false;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.explorer-import-dialog-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>