<template>
  <div class="explorers">
    <XDataTable
        table-name="Explorers"
        :headers="headers"
        :items-request="services.explorerStatus.getExplorers"
        :items-request-params="itemsRequestParams"
        :crud-requests="crudRequests"
        :additional-row-actions="additionalRowActions"
        dialog-width="1800"
        unpadded-dialog
        :row-style="getRowStyle"
        :item="item"
        :csvHeaders="csvHeaders"
        search
        :search-text="searchText"
        @search="searchText = $event"
        @update-item="item = $event"
        history>
      <template #after-new>
        <XBtn text="Import CSV" color="primary" @click="explorerImportDialog = true"/>
      </template>
      <template #before-table-name>
        <XBtn text="Get Explorer" icon="mdi-cloud-download-outline" color="primary" @click="getExplorerDialog = true"/>
      </template>
      <template #before-search>
        <XSelect label="Status Filter" v-model="statusFilter" :items="statusList" required :autocomplete="true"/>
        <TagQueryTextField v-model="tagsFilter" class="tag-query-text-field" id="explorers_tag_query"/>
      </template>
      <template #after-search>
        <XBtn text="Clear Filter" icon="mdi-filter-remove" color="primary" @click="clearFilter"/>
      </template>
      <template #above-table>
        <TotalAndSplit
            v-model="explorerCounts" :splits="explorerSplits" @split-click="setOnlineFilter"/>
      </template>
      <template #[`item.name`]="{value, row, rowIndex, handleNameClick}">
        <div class="explorer-name">
          <ExplorerHwBrand :degust-image-version="row.systemInfo.systemType.degustImageVersion" size="24"/>
          <ClickableText :text="value" @click="handleNameClick(row, rowIndex)"/>
          <div v-if="row['alias']">({{ row['alias'] }})</div>
        </div>
      </template>
      <template #[`item.status`]="{value}">
        <ExplorerStatus :value="value"/>
      </template>
      <template #[`item.tags`]="{value}">
        <div class="tags">
          <ClickableText v-for="(tag, i) of value" :key="i" :text="tag" @click="tagsFilter = tag"/>
        </div>
      </template>
      <template #dialog="{value, item, close, save}">
        <ExplorerDialog
            :value="value" :explorer="item" @input="close" @update-explorer="save"/>
      </template>
    </XDataTable>
    <ExplorerImportDialog v-model="explorerImportDialog"/>
    <GetExplorerDialog v-model="getExplorerDialog"/>
  </div>
</template>

<script>
import XDataTable from '@/components/basic/XDataTable';
import ClickableText from '@/components/basic/ClickableText';
import ExplorerHwBrand from '@/components/specific/ExplorerHwBrand';
import ExplorerStatus from '@/components/specific/ExplorerStatus';
import TotalAndSplit from '@/components/extended/TotalAndSplit';
import XBtn from '@/components/basic/XBtn';
import XSelect from '@/components/basic/XSelect';
import TagQueryTextField from '@/components/extended/TagQueryTextField';
import ExplorerImportDialog from '@/components/specific/ExplorerImportDialog';
import ExplorerDialog from '@/components/specific/ExplorerDialog';
import GetExplorerDialog from '@/components/specific/GetExplorerDialog';

export default {
  name: 'Explorers',
  components: {
    GetExplorerDialog,
    ExplorerDialog,
    ExplorerImportDialog,
    TagQueryTextField,
    XSelect,
    XBtn,
    TotalAndSplit,
    ExplorerStatus,
    ExplorerHwBrand,
    ClickableText,
    XDataTable,
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: 'MAC address',
          value: 'systemInfo',
          formatter: (value) => {
            return value ? value.systemType.networkInterfaces.eth0 : '';
          },
        },
        {
          text: 'Status',
          value: 'status',
          sortable: true,
        },
        {
          text: 'Description',
          value: 'description',
          sortable: true,
        },
        {
          text: 'Tags',
          value: 'tags',
        },
      ],
      additionalRowActions: [
        {
          text: 'Explorer Tests',
          icon: 'mdi-table-eye',
          click: this.openExplorerTests,
        },
        {
          text: item => !item.active ? 'Activate Explorer' : 'Deactivate Explorer',
          icon: item => !item.active ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off',
          click: (item, i, reload) => {
            this.services.explorerStatus.toggle(item.id, !item.active, () => {
              this.$store.commit('notification',
                  {text: `Successfully ${!item.active ? 'activated' : 'deactivated'} ${item.name}.`});
              reload();
            });
          },
        },
        {
          text: 'Reboot Explorer',
          icon: 'mdi-restart',
          click: (item, i, reload) => {
            this.$store.commit('notification', {text: `Sent request to reboot ${item.name}. Please wait.`});
            this.services.explorerStatus.reboot(item.name, () => {
              this.$store.commit('notification', {text: `Successfully rebooted ${item.name}.`});
              reload();
            }, () => {
              this.$store.commit('notification', {text: `Could not reboot ${item.name}.`});
            });
          },
        },
      ],
      dialog: false,
      item: null,
      explorerCounts: {},
      explorerSplits: {
        total: {
          text: (value) => `Total: ${value}`,
          clickParams: [-1],
        },
        online: {
          text: (value) => `Online: ${value}`,
          color: 'ok',
          clickParams: [1],
        },
        warning: {
          text: (value) => `Warning: ${value}`,
          color: 'testStatusWarning',
          clickParams: [2],
        },
        offline: {
          text: (value) => `Offline: ${value}`,
          color: 'testStatusError',
          clickParams: [0],
        },
      },
      onlineFilter: -1,
      statusFilter: -1,
      statusList: [
        {
          text: 'All',
          value: -1,
        },
        {
          text: 'In Operation',
          value: 4,
        },
        {
          text: 'Operation Disrupted',
          value: 9,
        },
        {
          text: 'Installed',
          value: 1,
        },
        {
          text: 'In Stock',
          value: 2,
        },
        {
          text: 'Shipping',
          value: 3,
        },
        {
          text: 'Activation Delayed',
          value: 10,
        },
        {
          text: 'Under Observation',
          value: 7,
        },
        {
          text: 'Observation Disrupted',
          value: 8,
        },
        {
          text: 'Not Connected',
          value: 11,
        },
        {
          text: 'None',
          value: 0,
        },
        {
          text: 'Warning',
          value: 5,
        },
        {
          text: 'Error',
          value: 6,
        },
        {
          text: 'Return',
          value: 12,
        },
        {
          text: 'Return Received',
          value: 13,
        },
        {
          text: 'Return Delayed',
          value: 14,
        },
        {
          text: 'Disposed',
          value: 15,
        },
        {
          text: 'Delete',
          value: 16,
        },
      ],
      tagsFilter: '',
      searchText: '',
      explorerImportDialog: false,
      getExplorerDialog: false,
    };
  },
  created() {
    this.getExplorerCounts();
  },
  watch: {
    statusFilter() {
      this.getExplorerCounts();
    },
    tagsFilter() {
      this.getExplorerCounts();
    },
    search() {
      this.getExplorerCounts();
    },
  },
  computed: {
    crudRequests() {
      return {
        get: {
          request: this.services.explorerStatus.getExplorer,
        },
        update: {
          request: this.services.explorerStatus.updateExplorer,
        },
        delete: {
          request: this.services.explorerStatus.deleteExplorer,
        },
      };
    },
    itemsRequestParams() {
      return [this.statusFilter, this.tagsFilter, this.onlineFilter];
    },
    csvHeaders() {
      const csvHeaders = [...this.headers];
      csvHeaders.find(x => x.value === 'status').formatter = (value) => {
        return this.statusList.find(x => x.text.replace(' ', '') === value.current).text;
      };
      csvHeaders.splice(2, 0, {
        text: 'Update Time',
        value: 'status',
        formatter: value => this.unixToDateTimeString(value.updateTime),
      }, {
        text: 'Event Time',
        value: 'status',
        formatter: value => this.unixToDateTimeString(value.eventTime),
      });
      csvHeaders.find(x => x.value === 'tags').formatter = value => `"${value.join(', ')}"`;
      csvHeaders.push({
        text: 'Operational State',
        value: 'status',
        formatter: value => {
          switch (value.online) {
            case 0:
              return 'Offline';
            case 1:
              return 'Online';
            case 2:
              return 'Warning';
          }
        },
      }, {
        text: 'Operational State Since',
        value: 'status',
        formatter: value => this.unixToDateTimeString(value.uptimeUpdateTime),
      });
      return csvHeaders;
    },
  },
  methods: {
    setOnlineFilter(key, value, onlineFilter) {
      this.onlineFilter = onlineFilter;
    },
    getRowStyle(explorer) {
      if (!explorer.active) return 'background-color: var(--v-tableRowDeactivated-base); border-bottom: 1px solid var(--v-tableBorderDeactivated-base);';
      return '';
    },
    clearFilter() {
      this.onlineFilter = -1;
      this.statusFilter = -1;
      this.tagsFilter = '';
      this.searchText = '';
    },
    openExplorerTests(item) {
      this.safeClick(`?f=testing&f2=explorerTests&name=${encodeURI(item.name)}`);
    },
    getExplorerCounts() {
      this.services.explorerStatus.getExplorerCounts(this.statusFilter, this.tagsFilter, this.searchText, (counts) => {
        this.explorerCounts = counts;
      });
    },
  },
};
</script>

<style scoped>
.explorers {
  height: 84vh;
}

.explorer-name {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}

.tags {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
}

.tag-query-text-field {
  flex-grow: 1;
  max-width: 350px;
}
</style>